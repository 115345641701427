$(document).ready(function () {
    // Detectar la ruta de la barra de navegación
    var domain = 'https://tacoexpresscolorado.com/';
    var home_ = domain;
    var about_ = domain + 'about';
    var menu_ = domain + 'menu';
    var accessibility_ = domain + 'accessibility';
    const pages = [
        { page: 'home', file: home_ },
        { page: 'about', file: about_ },
        { page: 'menu', file: menu_ },
        { page: 'accessibility', file: accessibility_ }
    ];

    $('.home, .about, .menu, .accessibility').each(function () {
        $(this).click(function () {
            var clickedClass = $(this).attr('class').split(' ')[0];

            var pageObj = pages.find(function (page) {
                return page.page === clickedClass;
            });

            if (pageObj) {
                var file = pageObj.file;

                window.location.href = file;
                //console.log('Haz clic en ' + file);
            }
        });
    });

    //add nav activo
    function agregarClaseNavActivo() {
        var currentPath = window.location.pathname;

        $('.page').each(function () {
            var link = $(this).text().toLowerCase();

            if (currentPath.includes(link)) {
                $(this).addClass('nav_activo');
            }
        });

        if (currentPath.endsWith("/")) {
            $('.home').addClass('nav_activo');
        }
    }

    $(document).ready(function () {
        agregarClaseNavActivo();
    });


    // Si o sí
    var allowedPaths = ['/home', '/about', '/'];
    var currentPath = window.location.pathname.replace('.html', '');
    //console.log('Ubicación: ' + currentPath);

    if (allowedPaths.includes(currentPath)) {
        $('[data-fancybox="gallery"], [data-fancybox="gallery_2"]').fancybox({
            hash: false
        });
    }

    // Agregar clase al cargar
    $('header section img').addClass('transition');
    $('body main article.content section:nth-child(1) div').addClass('fade-from-left');
    $('body main article.content section:nth-child(2) img').addClass('fade-from-right');
    $('body main article.info_btn span.address_link i').addClass('bounce-in');

    // Función de desplazamiento
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 30) {
            $('header').addClass('sticky_header');
        } else {
            $('header').removeClass('sticky_header');
        }
    });

    // Condición del botón "up"
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 200) {
            $('.up').addClass('show_btn');
        } else {
            $('.up').removeClass('show_btn');
        }
    });

    // Hacer clic fuera
    $(document).on('click', function (event) {
        var button = $('.order');
        var dropdown = $('.orders');
        if (!dropdown.is(event.target) && !button.is(event.target)) {
            dropdown.css('display', 'none');
            dropdown.removeClass('despliega');
            $(button).removeClass('rotate_reverse');
        }
    });

    // Menú desplegable
    $('.order').on('click', function () {
        var dropdown = $('.orders');
        if (dropdown.css('display') == 'none' || dropdown.css('display') == '') {
            dropdown.css('display', 'flex');
            dropdown.addClass('despliega');
            $(this).addClass('rotate_reverse');
        } else {
            dropdown.css('display', 'none');
            dropdown.removeClass('despliega');
            $(this).removeClass('rotate_reverse');
        }
    });

    // Botón para ir arriba
    var upUp = $(".up");
    upUp.on("click", function () {
        $(window).scrollTop(0);
        //console.log("Vamos arriba");
    });

    // Establecer el año
    var yir = new Date().getFullYear();
    var yy = new Date();
    $('.year').text(yir);
    console.log(yy);

    // Pestañas del menú de comida
    function menuTabs() {
        var tabs = $('.menu_tabs');
        var menuDivs = $('.mm');

        menuDivs.hide();

        menuDivs.eq(0).show();
        tabs.eq(0).addClass('colorea');

        tabs.each(function (index) {
            $(this).click(function () {
                menuDivs.hide();

                menuDivs.eq(index).show();

                tabs.removeClass('colorea');
                $(this).addClass('colorea');

                //console.log('Pestaña clickeada: ' + $(this).text());
                //console.log('Div mostrado: ' + menuDivs.eq(index).attr('class'));
            });
        });
    }

    menuTabs();

    // Establecer href 'home.html' al hacer clic en el logotipo
    $('.logo').click(function () {
        window.location.href = domain;
    });

    // Expandir li de la barra de navegación en dispositivos móviles
    $('li.silence button').on('click', function () {
        console.log('Vamos bien');
        $(this).toggleClass('clickeado');
        //$('header nav ul').toggleClass('expandete');
        $('.nav_mobile_mob').toggleClass('expandete');
    });

    //delete html to url
    function removeHtmlExtension(url) {
        if (url.endsWith('.html')) {
            return url.slice(0, -5);
        }
        return url;
    }

    //scroll-to
    $(document).ready(function () {
        // Capturar el evento clic en los enlaces internos
        $('span[data-scroll-to]').click(function (event) {
            event.preventDefault();

            var target = $(this).data('scroll-to');
            var offset = $(target).offset().top;

            $('html, body').animate({ scrollTop: offset }, 500);
        });
    });


    // Medir el vh
    const alturaViewport = $(window).height();
    const vhEquivalentes = (600 / alturaViewport) * 100;

    console.log(vhEquivalentes);
});
